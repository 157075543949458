<template>
	<div class="container--880 mypage">
		<div class="mypage__header">
			<h1>회원 탈퇴</h1>
			<span>
				사용 중인 아이디는 회원 탈퇴 후 재사용 및 복구가 불가하오니, 신중하게
				선택하시기 바랍니다.
				<br />탈퇴 후 회원정보 및 이용기록은 모두 삭제되며, 삭제된 데이터는
				복구되지 않습니다.
			</span>
		</div>
		<div class="mypage__wrapper withdrawal">
			<div class="mypage__title">
				<h2>회원 탈퇴 안내</h2>
			</div>
			<div class="mypage__withdrawal-text">
				<p>
					“포체인스”는 정상적인 조건에서 “회원”이 요청한 날짜에 중지 일정에 따라
					<br />서비스를 중지시키거나 서비스 계약을 종료합니다. <br />그러나
					“회원”이 계약 해지를 요청한 날 납부해야 할 이용요금이 남아 있다면<br />
					해당 “회원”이 이용요금을 납부 완료한 후에야 해지 절차를 진행할 수
					있습니다.
				</p>
			</div>
			<div class="userguide_btnbox">
				<button class="btn--md btn--fill-accent">
					<router-link to="/mypage">마이 페이지 </router-link>
				</button>
				<button class="btn--md btn--fill-gray">
					<router-link class="link--color-black" to="/inquiry"
						>1:1문의</router-link
					>
				</button>
			</div>
			<div class="mypage__withdrawal-text">
				<p>
					* 유의사항 * <br /><br />
					회원가입 후 탈퇴에 대한 개인정보 보관 기간 및 “회원” 로그인 기록,<br />
					작성한 글 보관 기간은 아래의 내용에 따릅니다.
				</p>
			</div>
			<!-- 회원탈퇴 정보 테이블 -->
			<table class="table__withdrawal">
				<tr>
					<th>보관하는 정보</th>
					<th>보존이유</th>
					<th>보존기간</th>
				</tr>
				<tr>
					<td>계약 또는 회원 탈퇴에 관한 기록</td>
					<td rowspan="3">전자상거래 등에서 소비자 보호에 관한 법률</td>
					<td rowspan="3">5년</td>
				</tr>
				<tr>
					<td>대금결제 및 재화 등의 공급에 대한 기록</td>
				</tr>
				<tr>
					<td>소비자의 불만 또는 분쟁 처리에 관한 기록</td>
				</tr>
				<tr>
					<td>전자 금융 거래에 관한 기록</td>
					<td>전자금융거래법</td>
					<td>5년</td>
				</tr>
				<tr>
					<td>저작권 신고에 대한 기록</td>
					<td>저작권법</td>
					<td>1년</td>
				</tr>
				<tr>
					<td>로그인 기록</td>
					<td>통신비밀보호법</td>
					<td>3개월</td>
				</tr>
			</table>

			<!-- 폼 -->
			<div class="form__mypage">
				<!--아이디-->
				<div class="form__group--mypage">
					<div class="form__label">
						<label class="withdrawal__label" for="input-1">* 아이디</label>
					</div>
					<span
						><strong>{{ $store.state.userid }}</strong></span
					>
				</div>

				<!-- 비밀번호 -->
				<div class="form__group--mypage">
					<div class="form__label">
						<label for="input-2">* 비밀번호</label>
					</div>
					<div class="form__group" id="input-margin">
						<input
							id="input-1"
							v-model="withdrawal.userPw"
							placeholder="비밀번호(필수)"
							maxlength="20"
							type="password"
						/>
					</div>
				</div>
				<!-- 탈퇴사유 -->
				<div class="form__group--mypage">
					<div class="form__label">
						<label for="input-3">* 탈퇴 사유</label>
					</div>
					<div class="form__group" id="input-margin">
						<select
							v-model="withdrawal.selected"
							name="withdrawlReason"
							id="input-3"
						>
							<option value="">탈퇴 사유(필수)</option>
							<option value="nothing">사용할 서비스/솔루션이 없어서</option>
							<option value="privacy">개인정보를 삭제하고 싶어서</option>
							<option value="changeId">새로운 계정으로 가입하고 싶어서</option>
							<option value="not_used">
								fcts 서비스를 더 이상 이용하지 않을 거 같아서
							</option>
							<option value="Use_something_else">
								다른 서비스를 이용 중이라서
							</option>
							<option value="dissatisfaction">
								혜택과 서비스가 불만족스러워서
							</option>
							<option value="error">오류로 인해 사용할 수 없어서</option>
							<option value="Other_inquiries">기타</option>
						</select>
					</div>
				</div>
			</div>
			<!--동의하기-->
			<div class="agree__group">
				<input type="checkbox" id="checkbox-1" v-model="withdrawal.agree" />
				<span>해당 내용을 모두 확인하였으며, 회원 탈퇴에 동의합니다.</span>
			</div>
			<div class="userguide_btnbox">
				<button
					:disabled="!withdrawalCheck"
					class="btn--md btn--fill-accent"
					:class="{ btnDisabled: !withdrawalCheck }"
					@click="submitWithdrawal"
				>
					회원탈퇴
				</button>
				<button class="btn--md btn--fill-gray">
					<router-link class="link--color-black" to="/mypage"
						>돌아가기</router-link
					>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import http from '../../api/http-common';
import userinfoDataService from '../../services/userinfoDataService';

export default {
	data() {
		return {
			withdrawal: {
				selected: '',
				agree: '',
				userPw: '',
			},
		};
	},
	computed: {
		withdrawalCheck() {
			return (
				this.withdrawal.userPw &&
				this.withdrawal.agree &&
				this.withdrawal.selected
			);
		},
	},
	methods: {
		async submitWithdrawal() {
			try {
				const confirmed = confirm('정말 탈퇴하시겠습니까?');

				if (!confirmed) {
					return;
				}

				// 회원 탈퇴 요청
				await http.post('/withdrawal', {
					username: this.$store.state.userid,
					userPw: this.withdrawal.userPw,
					selected: this.withdrawal.selected,
					agree: this.withdrawal.agree,
				});

				// 수정된 부분: 응답을 콘솔에 출력
				// console.log(response);
				console.log('탈퇴 요청 완료');

				// 회원 탈퇴 완료 후 로그아웃 및 로컬 스토리지 초기화
				this.$store.commit('clearUserid');
				userinfoDataService.removeStorage('user'); // 로컬 스토리지에서 사용자 정보 삭제
				userinfoDataService.removeStorage('vuex'); // 로컬 스토리지에서 사용자 정보 삭제

				// 회원 탈퇴 완료 페이지로 이동
				this.$router.replace('/mypage/withdrawal/complete').catch(() => {});
			} catch (error) {
				console.error('회원 탈퇴 오류 프론트:', error.message);
				// 오류 처리 로직 추가
			}
		},
	},
};
</script>

<style></style>
